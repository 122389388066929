<template>
  <div class="col-md-12 col-lg-12 plan-card-wrap">
    <div
      v-bind:class="getClass(plan.identity, index)"
      @click="choosePlan(plan.identity)"
    >
      <div class="row header">
        <div class="head1">
          <AppIcon componentName="CheckIconNoBg" class="plan-card__icon" />
        </div>
        <div class="head2">
          <span class="plan-card__title1"
            >{{ plan.title }} {{ selectedPlan }}</span
          ><span class="plan-card__title2" v-if="index == 0">Most Popular</span>
        </div>
        <div class="head3">
          <span class="plan-card__currency"><sup>$</sup></span
          ><span class="plan-card__price">{{ plan.price }}</span>
        </div>
      </div>
      <div class="row">
        <div class="plan-card__description">
          <ul class="plan-card__options plan-options">
            <li
              v-for="option in plan.options"
              :key="option"
              class="plan-options__item"
            >
              <span>{{ option }}</span>
            </li>
          </ul>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import $ from "jquery";

export default {
  name: "SubscribePlanCardV2",
  props: {
    index: String,
    plan: {
      identity: String,
      title: String,
      options: Array,
      price: [Number, String],
      photo: String,
    },
  },
  data() {
    return {};
  },
  computed: {},
  created() {},
  methods: {
    choosePlan(planIdentity) {
      $(".plan-card").removeClass("active");
      $(".plan-" + planIdentity).addClass("active");
      this.$emit("choosePlan");
    },
    getClass(planIdentity, index) {
      if (index == 0) {
        return "plan-card active plan-" + planIdentity;
      } else {
        return "plan-card plan-" + planIdentity;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.header {
  width: 100%;
}
.head1 {
  width: 10%;
  float: left;
}
.head2 {
  width: 70%;
  text-align: left;
  padding-top: 8px;
  padding-left: 8px;
  float: left;
}
.head3 {
  width: 20%;
  color: #4874e6;
  padding-top: 5px;
  text-align: right;
  float: left;
}
.plan-icon-disabled {
  fill: #d9d9d9 !important;
  border: 1px solid #d9d9d9 !important;
  background-color: #d9d9d9 !important;
}
.plan-card-wrap {
  padding: 20px;
}
.plan-card {
  cursor: pointer;
  border: 0px solid #4874e6;
  border-radius: 10px;
  box-shadow: 0px 0px 15px #888;
  padding: 20px;
  width: 100%;

  &__icon {
    fill: #d9d9d9;
    border: 1px solid #d9d9d9;
    border-radius: 50px;
    padding: 5px;
    width: 40px;
    height: 40px;
    background-color: #d9d9d9;
  }

  &__title1 {
    font-size: 20px;
    font-weight: bold;
    color: #2e2e2e;
    margin-right: 10px;
  }

  &__title2 {
    font-size: 15px;
    color: #2e2e2e;
    font-style: italic;
  }

  &__currency {
    color: #2e2e2e;
    font-size: 25px;
  }
  &__price {
    color: #2e2e2e;
    font-size: 30px;
    font-weight: bold;
    @media (max-width: map_get($grid-breakpoints, md)) {
      padding-bottom: 10px;
      font-size: 2.2rem;
    }
  }
  &__description {
    display: none;
    margin-left: 63px;
    margin-top: 10px;
    margin-bottom: 10px;
    padding-right: 30px;
  }
}

.plan-options {
  &__item {
    position: relative;
    text-align: left;

    &:before {
      content: "";
      display: block;
      background-size: cover;
      background-image: url("~@/assets/images/subscribe-plans/plan-options-checkbox-icon2.png");
      width: 10px;
      height: 10px;
      position: absolute;
      margin-top: 8px;
    }

    &:last-of-type {
      margin: 0;
    }
  }

  span {
    padding-left: 20px;
    display: block;
  }
}

.active {
  border: 2px solid #4874e6 !important;
}
.active .plan-card__icon {
  fill: #fff !important;
  border: 1px solid #4874e6 !important;
  background-color: #4874e6 !important;
}
.active .plan-card__title1 {
  color: #4874e6 !important;
}
.active .plan-card__title2 {
  color: red !important;
}
.active .plan-card__currency {
  color: #4874e6 !important;
}
.active .plan-card__price {
  color: #4874e6 !important;
}
.active .plan-card__description {
  display: block !important;
}

@media only screen and (max-width: 1329px) {
  .header {
    padding-left: 10px;
  }
  .head2 {
    padding-left: 15px;
  }
  .head2 span {
    font-size: 17px;
    display: block;
  }
  .plan-card__currency {
    font-size: 20px;
  }
  .plan-card__price {
    font-size: 20px;
  }
  .plan-card__description {
    margin-left: 18px;
  }
}

@media only screen and (max-width: 991px) {
  .head2 span {
    font-size: 20px;
    display: inline;
  }
  .plan-card__currency {
    font-size: 25px;
  }
  .plan-card__price {
    font-size: 28px;
  }
  .plan-card__description {
    margin-left: 15px;
  }
}

@media only screen and (max-width: 500px) {
  .head2 {
    padding-left: 20px;
    width: 68%;
  }
  .head2 span {
    font-size: 15px;
    display: block;
  }
  .plan-card__currency {
    font-size: 20px;
  }
  .plan-card__price {
    font-size: 28px;
  }
  .plan-card__description {
    margin-left: 15px;
    font-size: 15px;
  }
  .plan-options span {
    padding-left: 40px;
  }
  .plan-card-wrap {
    padding: 15px;
  }
}
</style>
