<template>
  <section class="subscribe-section">
    <div class="container">
      <div class="row justify-content-center">
        <div class="subscribe-section__header col-lg-7">
          <h1 class="subscribe-section__title">
            Get Full Access to Resume Creator & More
          </h1>
          <p class="subscribe-section__description">
            Build your best resume yet. Subscribe to a plan that suits your
            requirements.
          </p>
        </div>
      </div>
      <div class="row">
        <div class="col-sm-12 col-lg-7 form-col resume">
          <div class="resume-box">
            <div
              class="resume-preview"
              :style="'background-image: url(' + previewData + ')'"
            ></div>
          </div>
          <div class="money-back">
            <strong>14-Day Money-Back Guarantee</strong>
            <br />
            If you're unhappy for any reason, during the first 14 days, we'll
            refund your money. <br />Just email or call 1-888-264-1699 to
            cancel.
          </div>
          <!-- TrustBox widget - Micro Review Count -->
          <div
            class="trustpilot-widget"
            data-locale="en-US"
            data-template-id="5419b6a8b0d04a076446a9ad"
            data-businessunit-id="6296b8c78da6978dc47acd97"
            data-style-height="24px"
            data-style-width="100%"
            data-theme="light"
            data-min-review-count="10"
          >
            <a
              href="https://www.trustpilot.com/review/resume-perfect.com"
              target="_blank"
              rel="noopener"
              ><img src="@/assets/images/subscribe-plans/trustpilot_seal.jpg"
            /></a>
          </div>
          <!-- End TrustBox widget -->
        </div>
        <div class="col-sm-12 col-lg-5 form-col">
          <div
            v-if="subscriptionPlans.length"
            class="row justify-content-center"
          >
            <SubscribePlanCardV2
              v-for="(plan, index) in subscriptionPlans"
              :key="index + plan.identity"
              :plan="plan"
              :index="index"
              @choosePlan="selectPlan(plan.identity)"
            />
          </div>
          <div class="desktop-button">
            <AppButton
              title="Continue"
              className="primary"
              @click.prevent="goToPay"
            />
          </div>
          <div class="mobile-button">
            <AppButton
              title="Continue"
              className="primary"
              @click.prevent="goToPay"
            />
          </div>
        </div>
        <div class="col-sm-12 col-lg-12 ad-box" style="display: none">
          <div class="we-found" v-if="showAdBox">
            We found <span>{{ adsCounter1 }} {{ adsJobTitle }}</span> near
            <span class="we-found__city">{{ adsCity }}</span>
          </div>
        </div>
        <div class="pop-box" v-if="showAdBox" style="display: none">
          <strong>{{ adsCounter2 }}</strong> people have downloaded their resume
          in the last
          <strong>24 hours</strong>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import SubscribePlanCardV2 from "@/components/subscribe-steps/SubscribePlanCardV2";
import { AUTH_NAMES, SUBSCRIBE_STEPS_NAMES } from "@/helpers/rout-constants";
import { mapGetters } from "vuex";
import { mixpanelMixin } from "@/mixins/MixPanelMixin";
import * as FullStory from "@fullstory/browser";
import CompletedTemplate from "@/helpers/completed-template-hash";

export default {
  name: "PricingFormV1",
  mixins: [mixpanelMixin],
  components: {
    SubscribePlanCardV2,
  },
  data() {
    return {
      selectedPlan: "",
      adsCity: "your location",
      adsCounter1: 2250,
      adsCounter2: 3120,
      adsJobTitle: "",
      showAdBox: false,
    };
  },
  computed: {
    ...mapGetters({
      isLoggedIn: "auth/isLoggedIn",
      subscriptionPlans: "subscribe/getSubscriptions",
      user: "user/user",
      previewAll: "resumeCreation/getResumePreviewAll",
    }),

    previewData() {
      let previewLink = "";

      for (let i = 0; i < this.previewAll.length; i++) {
        previewLink = this.previewAll[i];
        break;
      }
      return previewLink;
    },
  },
  methods: {
    selectPlan(planIdentity) {
      this.selectedPlan = planIdentity;
    },
    goToPay() {
      let planId = "";

      if (this.selectedPlan == "") {
        planId = this.subscriptionPlans[0].identity;
      } else {
        planId = this.selectedPlan;
      }

      this.$router.push({
        name: this.isLoggedIn
          ? SUBSCRIBE_STEPS_NAMES.PAYMENT_PAGE
          : AUTH_NAMES.LOGIN,
        params: {
          planId,
        },
      });
    },
    async getUserData() {
      try {
        await this.$store.dispatch("user/getUser");
      } catch (e) {
        console.error(e);
      }
    },
  },

  async created() {
    await this.$store.dispatch("loader/pending");

    this.mixpanelTrack("V2-PRICING");

    if (this.isLoggedIn) {
      await this.getUserData();
      FullStory.setUserVars({
        email: this.user.email,
      });
    }

    try {
      // let resume = await this.$store.dispatch(
      //   "resumeCreation/getResume",
      //   CompletedTemplate.get().hash
      // );

      await this.$store.dispatch(
        "resumeCreation/getResumePreviewAll",
        CompletedTemplate.get().hash
      );

      /**Ads Box**/
      // let work_id = 0;
      // for (let i = 0; i < resume.work_histories.length; i++) {
      //   this.adsCity = resume.work_histories[i].city;
      //   this.adsJobTitle = resume.work_histories[i].job_title + " jobs";
      //   work_id = resume.work_histories[i].id;
      // }

      // this.adsCounter1 =
      //   work_id != 0
      //     ? (parseFloat(this.adsCounter1) + parseFloat(work_id))
      //         .toString()
      //         .replace(/\B(?=(\d{3})+(?!\d))/g, ",")
      //     : this.adsCounter1;
      // this.adsCounter2 =
      //   work_id != 0
      //     ? (parseFloat(this.adsCounter2) + parseFloat(work_id))
      //         .toString()
      //         .replace(/\B(?=(\d{3})+(?!\d))/g, ",")
      //     : this.adsCounter2;

      // setTimeout(() => (this.showAdBox = true), 1500);
      /*********/
    } catch (err) {
      console.error(err);
    }

    await this.$store.dispatch("subscribe/getAllSubscriptionPlans");
    await this.$store.dispatch("loader/done");
  },
};
</script>

<style scoped lang="scss">
.ad-box {
  text-align: center;
  margin-top: 40px;
}
.pop-box {
  background-color: #2e2e2e;
  border: 1px solid #000;
  width: 240px;
  height: 89px;
  position: fixed;
  bottom: 0;
  color: #fff;
  right: 0;
  text-align: left;
  font-size: 12px;
  padding: 15px;
  margin-right: 5px;
  margin-bottom: 5px;
}
.we-found {
  color: #2e2e2e;
  font-size: 25px;
  font-weight: bold;

  /*  &__city {
    text-transform: capitalize;
  }
*/
  span {
    color: #4874e6;
  }
}
.resume {
  padding: 20px;
}
.resume-box {
  width: 100%;
  border: 60px solid #2e2e2e;
  border-radius: 10px;
  height: 500px;
  padding: 0px;
}
.resume-preview {
  width: 100%;
  background-repeat: no-repeat;
  background-size: cover;
  height: 100%;
}
.money-back {
  background-color: #d9d9d9;
  width: 100%;
  margin-top: -114px;
  padding: 20px;
  text-align: center;
  border-radius: 0px 0px 10px 10px;
  font-size: 0.75rem;
  text-align: center;
}
.subscribe-section {
  padding: 80px 0 80px;

  @media (min-width: map_get($grid-breakpoints, lg)) {
    padding: 80px 0 105px 0;
    text-align: center;
  }

  &__title {
    @media (max-width: map_get($grid-breakpoints, lg)) {
      font-size: 24px;
    }
    @media (max-width: 375px) {
      font-size: 20px !important;
    }
  }

  &__description {
    font-size: 14px;
    line-height: 140%;
    color: $light-gray;
    margin-top: 16px;
    margin-bottom: 32px;
    @media (max-width: 375px) {
      font-size: 12px !important;
      margin-top: 8px !important;
      margin-bottom: 14px !important;
    }
  }
}
.desktop-button {
  margin-top: 20px;
}
.desktop-button button {
  width: 100%;
}
.trustpilot-widget {
  margin-top: 30px;
}
.mobile-button {
  display: none;
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  padding: 10px;
  z-index: 9;
  background: #fff;
}
.mobile-button button {
  width: 100%;
}

@media only screen and (max-width: 991px) {
  .resume {
    display: none;
  }
  .desktop-button {
    display: none;
  }
  .mobile-button {
    display: block;
  }
  .ad-box {
    display: none;
  }
  .pop-box {
    display: none;
  }
}
</style>
